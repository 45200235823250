<template>
  <b-row>
    <b-col md="8">
      <b-card-normal title="Firma Bilgileri" :showLoading="show">
        <template v-slot:body>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
              <b-row>
                <b-col cols="12" md="6">
                  <validation-provider name="Firma Adı" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Firma Adı">
                      <b-form-input
                        class="rounded-0"
                        ref="firma_adi"
                        v-model="form.firma_adi"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />

                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                  <validation-provider name="Yetkili Ad Soyad" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Yetkili Ad Soyad">
                      <b-form-input
                        class="rounded-0"
                        ref="yetkili_ad_soyad"
                        v-model="form.yetkili_ad_soyad"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />

                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Firma Fatura Başlık">
                    <b-form-input class="rounded-0" ref="firma_fatura_baslik" v-model="form.firma_fatura_baslik" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <validation-provider name="Adres" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Adres">
                      <b-form-input
                        class="rounded-0"
                        ref="adres"
                        v-model="form.adres"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />

                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <validation-provider name="Telefon" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Telefon">
                      <b-form-input
                        class="rounded-0"
                        ref="telefon"
                        v-model="form.telefon"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />

                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                  <validation-provider name="E Mail" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="E Mail">
                      <b-form-input
                        class="rounded-0"
                        ref="e_mail"
                        v-model="form.e_mail"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />

                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Firma Logo">
                    <b-form-file
                      class="rounded-0"
                      placeholder="Bir resim seçiniz"
                      drop-placeholder="Drop file here..."
                      no-drop
                      v-model="logo"
                      accept="image/jpeg, image/png, image/jpg"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-img fluid-grow :src="handlerLogoShow(form)" />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button squared block type="submit" variant="primary">
                    <feather-icon icon="CheckSquareIcon" class="mr-50" />
                    <span class="align-middle">{{ form._id == null ? 'Ekle' : 'Güncelle' }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </template>
      </b-card-normal>
    </b-col>
  </b-row>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
export default {
  components: {
    BCardNormal,
  },
  data: () => ({
    show: false,
    logo: null,
    form: {
      _id: null,
      firma_adi: null,
      firma_fatura_baslik: null,
      yetkili_ad_soyad: null,
      adres: null,
      telefon: null,
      e_mail: null,
    },
  }),
  computed: {
    handlerLogoShow() {
      return (val) => {
        if (val != null) {
          const base_url =
            process.env.NODE_ENV === 'development'
              ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
              : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
          return `${base_url}uploads/logo/${val.logo}`;
        }
      };
    },
  },
  created() {
    this.handlerFirmaConfig();
  },
  methods: {
    async handlerFirmaConfig() {
      this.show = true;
      await this.$store.dispatch('firmaBilgiGetir').then((res) => {
        if (res.data.data !== null) {
          this.form = res.data.data;
        }
        this.show = false;
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      this.show = true;
      let formData = new FormData();
      let forms = this.form;
      Object.keys(forms).map(function (key) {
        formData.append(key, forms[key]);
      });
      if (this.logo !== null) {
        formData.append('logo', this.logo, this.logo.name);
      }
      this.$store
        .dispatch('firmaBilgiEkle', formData)
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: this.form._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
              },
            });
            this.handlerFirmaConfig();
            this.show = false;
          }
        })
        .catch((err) => {
          this.show = false;
          if (err.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
